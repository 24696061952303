import moment from 'moment';
import _ from 'underscore';
import Analytics from '@services/analytics';
import OrderPriceStore from '@services/order_price_store';
import SelectedCityStore from '@components/shared/selected_city_store';


window.moment = moment;
window._ = _;
window.Analytics = Analytics;
window.OrderPriceStore = OrderPriceStore;
window.SelectedCityStore = SelectedCityStore;
